import React, { useRef } from "react";
import * as PR from "../../prime-modules/index";
import { whiteLogo } from "../../assets/images";
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { adminAuthActions } from "../../store/adminAuth";

const AdminHeader = () => {
  const menu = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const appName = process.env?.REACT_APP_NAME;




  const items = [
    {
        label: "Logout",
        icon: "pi pi-power-off",
        command : () => {
          dispatch(adminAuthActions.onLogout())
        }
    }
  ];

  const menuItems = [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-th-large',
      command: () => {navigate('/dashboard')},
      className: (pathname === '/dashboard') ? 'active-menu' : '',
      enable: true
    },
    {
      label: 'Orders',
      icon: 'pi pi-fw pi-shopping-cart',
      command: () => {navigate('/orders')},
      className: (pathname.includes('/orders')) ? 'active-menu' : '',
      enable: true
    },
    {
      label: 'Customers',
      icon: 'pi pi-fw pi-user',
      command: () => {navigate('/users')},
      className: (pathname === '/users' || pathname.includes('/user-orders')) ? 'active-menu' : '',
      enable: true
    },
    {
      label: 'eSIM Details',
      icon: 'pi pi-fw pi-users',
      command: () => {navigate('/esim-Details')},
      className: (pathname === '/' || pathname.includes('/esim-Details')) ? 'active-menu' : '',
      enable: true
    },
    {
      label: 'Affiliates',
      icon: 'pi pi-sitemap',
      command: () => {navigate('/affiliates')},
      className: (pathname === '/affiliates') ? 'active-menu' : '',
      enable: appName !== 'data2go-iot'
    },
    {
      label: 'Price Adjustment',
      icon: 'pi pi-fw pi-dollar',
      command: () => {navigate('/price-adjustment')},
      className: (pathname === '/price-adjustment') ? 'active-menu' : '',
      enable: appName !== 'data2go-iot'
    },
    {
      label: 'Discount Vouchers',
      icon: 'pi pi-percentage',
      command: () => {navigate('/vouchers')},
      className: (pathname === '/vouchers') ? 'active-menu' : '',
      enable: appName !== 'data2go-iot'
    },
    {
      label: 'Prepaid Vouchers',
      icon: 'pi pi-ticket',
      command: () => {navigate('/prepaid-retail-vouchers')},
      className: (pathname === '/prepaid-retail-vouchers') ? 'active-menu' : '',
      enable: appName !== 'data2go-iot'
    },
    {
      label: 'Site Maintenance',
      icon: 'pi pi-fw pi-globe',
      command: () => {navigate('/sitemaintenance')},
      className: (pathname === '/sitemaintenance') ? 'active-menu' : '',
      enable: appName !== 'data2go-iot'
    },
    {
      label: appName === 'data2go-iot' ? 'Language Translate' : 'CMS',
      icon: 'pi pi-fw pi-cog',
      command: () => {navigate('/cms')},
      className: (pathname === '/cms') ? 'active-menu' : '',
      enable: true
    }
  ].filter(m => m.enable);

  const end = <div className="flex align-items-center">
    <span className="user-icon"><i className="pi pi-power-off"></i></span>
    <PR.Button
        label="Logout"
        icon="pi pi-arrow-right"
        iconPos="right"
        onClick={() =>  dispatch(adminAuthActions.onLogout())}
    />
    <PR.Menu model={items} popup ref={menu} />
    </div>;

 /*  const newItems = [
    {
        label: 'Vouchers',
        icon: 'pi pi-palette',
        items: [
            {
                label: 'Styled',
                icon: 'pi pi-eraser',
                url: '/theming'
            },
            {
                label: 'Unstyled',
                icon: 'pi pi-heart',
                url: '/unstyled'
            }
        ]
    },
    {
        label: 'External',
        icon: 'pi pi-home',
        url: 'https://react.dev/'
    }
  ];
 */
  return (
    <>
      <Helmet>
        <title>data2go: TAKE A BYTE | Backoffice</title>
      </Helmet>
      <section className="admin-header-section">
        <div className="flex align-items-center">
          <Link to="/"><PR.Image src={whiteLogo} alt="Logo" /></Link>
        </div>
      </section>
      <div className="w-12">
        <header className="admin-header">
          <PR.Menubar model={menuItems} end={end} className="w-12" />
          {/* <PR.PanelMenu model={newItems} className="w-12" end={end} /> */}
        </header>
      </div>
    </>
  )
}

export default AdminHeader