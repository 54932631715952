import React, { useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../layout/admin-header.jsx";
import * as PR from "../../prime-modules/index";
import { useDispatch, useSelector } from "react-redux";
import { esimsICCID } from "../../services/api.jsx";
import "./EsimDetails.scss";
import AdminFooter from "../layout/admin-footer.jsx";

const SearchBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const toast = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [createLoading, setCreateLoading] = useState(false);

  const handleSearch = () => {
    if (!searchTerm.trim()) {
      toast.current.show({
        severity: "warn",
        summary: "Validation Error",
        detail: "Please enter a valid ICCID.",
      });
      return;
    }

    setCreateLoading(true);
    const getResponse = (response) => {
      setCreateLoading(false);
      if (response.result === "SUCCESS") {
        navigate(`/esim-details/${searchTerm}`, { state: { esimdata: response.data } });
      } else {
        const error = response.error;
        toast.current.show({
          severity: error.severity || "error",
          summary: "Error",
          detail: error.errorMsg || error.summary || "An unknown error occurred.",
        });
      }
    };
    esimsICCID(searchTerm, headers, dispatch, getResponse);

  };

  return (
    <div className="main">
      <div className="layout-sidebar">
        <AdminHeader />
      </div>
      <div className="layout-content-wrapper">
        <section className="iccid-search-section">
          <div className="search-block">
            <h2>Enter the ICCID</h2>
            <div className="p-inputgroup">
              <PR.InputText
                value={searchTerm}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setSearchTerm(numericValue)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !createLoading) {
                    handleSearch();
                  }
                }}
                placeholder="Search by ICCID"
                className="p-inputtext-lg"
                disabled={createLoading} // Disable input while loading
              />
              <span className="p-inputgroup-addon">
                {createLoading ? (
                  <i className="pi pi-spin pi-spinner" style={{ cursor: "not-allowed" }} />
                ) : (
                  <i
                    className="pi pi-search"
                    style={{ cursor: "pointer" }}
                    onClick={handleSearch}
                  />
                )}
              </span>
            </div>
          </div>
        </section>
        <AdminFooter />
      </div>
      <PR.Toast ref={toast} />
    </div>
  );
};

export default SearchBox;
